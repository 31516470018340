.circular {
    animation: rotate 2s linear infinite;
    height: 100%;
    transform-origin: center center;
    width: 100%;
}

.circular .path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: dash 1.5s ease-in-out infinite;
    stroke-linecap: round;
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
   }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
   }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px;
   }
    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px;
   }
}