.phone {
    animation: rotation 3s infinite cubic-bezier(0.76, 0, 0.24, 1);
    transform-origin: 50% 50%;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    30%, 50% {
        transform: rotate(90deg);
    }
    80%, 100% {
        transform: rotate(0deg);
    }
}