* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.font-logo {
  font-family: Alata, sans-serif;
  font-weight: 400;
}

.font-body {
  font-family: DM Sans ss02, sans-serif;
}

.font-heading {
  font-family: PP Woodland, sans-serif;
}

@font-face {
  font-family: 'PP Woodland';
  src: url('assets/fonts/ppwoodland-regular-webfont.woff2') format('woff2'),
       url('assets/fonts/ppwoodland-regular-webfont.woff') format('woff');
  font-weight: normal;
}

@font-face {
  font-family: 'PP Woodland';
  src: url('assets/fonts/ppwoodland-bold-webfont.woff2') format('woff2'),
       url('assets/fonts/ppwoodland-bold-webfont.woff') format('woff');
  font-weight: bold;
}
 
@font-face {
  font-family: 'DM Sans ss02';
  src: url('assets/fonts/dmsans-regular-webfont.woff2') format('woff2'),
       url('assets/fonts/dmsans-regular-webfont.woff') format('woff');
  font-weight: normal;
} 

@font-face {
  font-family: 'DM Sans ss02';
  src: url('assets/fonts/dmsans-bold-webfont.woff2') format('woff2'),
       url('assets/fonts/dmsans-bold-webfont.woff') format('woff');
  font-weight: bold;
}
 
.h-2px {
  height: 2px;
}

/* 
  Source:
  https://stackoverflow.com/questions/24579785/force-iframe-youtube-video-to-center-fit-and-full-cover-the-screen-in-the-backgr
  */
.video-background {
  background: white;
  position: fixed;
  top: 0; right: 0; bottom: 0; left: 0;
  z-index: -99;
}
.video-foreground,
.video-background iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

@media (min-aspect-ratio: 16/9) {
  .video-foreground { height: 300%; top: -100%; width: 100%; left: auto; }
}
@media (max-aspect-ratio: 16/9) {
  .video-foreground { width: 300%; left: -100%; height: 100%; top: auto; }
}
@media all and (max-width: 600px) {
  .vid-info { width: 50%; padding: .5rem; }
  .vid-info h1 { margin-bottom: .2rem; }
}
@media all and (max-width: 500px) {
  .vid-info .acronym { display: none; }
}